import React, { useContext, useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import { Button, Grid, Header } from 'semantic-ui-react'
import { CSSTransition } from 'react-transition-group'

import { BallLeftDown, BallsRightUp } from '../../Assets'
import PassengerAlert from '../../Components/PassengerAlert/PassengerAlert'
import animationData from '../../Assets/Live.json'

import './Event.scss'
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription
} from '@apollo/client'
import {
  ON_NEW_QUESTION,
  USER_ME,
  ON_ANSWER_QUESTION,
  CREATE_QUESTION,
  USER_MORE_ANSWERS
} from '../connections'
import LoaderContext from '../../Library/Context/LoaderContext'
import Carousel from '../../Components/Carousel/Carousel'
import FeedCard from '../../Components/FeedCard/FeedCard'
import Lottie from 'react-lottie'

export default function Event() {
  const { userInfo, token } = useSelector((state: any) => state)
  const { setLoading } = useContext(LoaderContext)

  const [_alert, setAlert] = useState<boolean>(false)
  const [alertData, setAlertData] = useState({
    title: 'Question'
  })
  const [questions, setQuestions] = useState<any[]>([])
  const [isAdmin, setIsAdmin] = useState<boolean>(false)

  const [createQuestion, e_CreateQuestion] = useMutation(CREATE_QUESTION, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    context: {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  })

  const { data: dataSubs, loading } = useSubscription(ON_NEW_QUESTION)
  const { data: dataAnswer, loading: loadingAnswer } =
    useSubscription(ON_ANSWER_QUESTION)

  const { data, loading: mainLoading } = useQuery(USER_ME, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    context: {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  })

  const [fetchMoreAnswers, e_MoreAnswers] = useLazyQuery(USER_MORE_ANSWERS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    context: {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  })

  useEffect(() => {
    setLoading(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setLoading(mainLoading || e_CreateQuestion.loading || e_MoreAnswers.loading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainLoading, e_CreateQuestion.loading, e_MoreAnswers.loading])

  useEffect(() => {
    if (!loading && dataSubs) {
      const { question } = dataSubs?.OnNewQuestion
      setAlert(true)
      setAlertData(question)
      setQuestions([question, ...questions])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSubs, loading])

  useEffect(() => {
    console.log(loadingAnswer, dataAnswer)
    if (!loadingAnswer && dataAnswer) {
      const { answer } = dataAnswer?.OnAnswerQuestion
      const _oldQ = [...questions]
      const _find = _oldQ.find((question) => question.id === answer.questionId)
      if (_find) {
        _find.Answers = [answer, ..._find?.Answers]
      }
      if (answer.authorId === userInfo.id) {
        _find.answered = true
      }

      setQuestions(_oldQ)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAnswer, loadingAnswer])

  useEffect(() => {
    if (e_MoreAnswers?.data?.UserMoreAnswers?.answers?.length > 0) {
      const { answers, enabledViewMore } = e_MoreAnswers?.data?.UserMoreAnswers

      const [{ questionId }] = answers

      const _oldQ = [...questions]
      const _find = _oldQ.find((question) => question.id === questionId)

      if (_find) {
        _find.Answers = [..._find?.Answers, ...answers]
        _find.enabledViewMore = enabledViewMore
      }

      setQuestions(_oldQ)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [e_MoreAnswers.data])

  useEffect(() => {
    if (data?.UserMe) {
      setIsAdmin(data.UserMe.isAdmin)
      if (data?.UserMe?.list.length > 0) {
        setQuestions(data.UserMe.list)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (_alert) {
      setTimeout(() => {
        setAlert(false)
      }, 8000)
    }
  }, [_alert])

  return (
    <div className='Event'>
      <CSSTransition
        in={_alert}
        timeout={300}
        classNames='alert'
        unmountOnExit
        onEnter={() => setAlert(true)}
        onExited={() => setAlert(false)}
      >
        <PassengerAlert alertData={alertData} />
      </CSSTransition>
      <Grid columns='one' divided>
        <Grid.Row>
          {isAdmin && (
            <Grid.Column
              style={{
                backgroundColor: '#38171b',
                textAlign: 'center',
                color: '#FFFFFF',
                minHeight: '48px'
              }}
            >
              <Grid columns='two'>
                <Grid.Column
                  textAlign='left'
                  style={{
                    height: '48px',
                    lineHeight: '76px'
                  }}
                >
                  <strong>Admin</strong>
                </Grid.Column>
                <Grid.Column
                  textAlign='right'
                  style={{
                    minHeight: '48px',
                    lineHeight: '48px'
                  }}
                >
                  <Button
                    onClick={() => {
                      const titleQuestion = prompt(
                        'Ingrese el titulo de la pregunta:'
                      )
                      if (!titleQuestion?.trim().length) {
                        alert('El titulo de la pregunta no puede estar vacio')
                        return
                      } else {
                        createQuestion({
                          variables: {
                            AdminCreateQuestionInput: {
                              title: titleQuestion,
                              author: `${userInfo.firstName} ${userInfo.lastName}`
                            }
                          }
                        })
                      }
                    }}
                  >
                    Nueva Pregunta
                  </Button>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          )}
          <Grid.Column
            style={{
              backgroundColor: 'rgba(103,163,213,1)',
              textAlign: 'center',
              paddingTop: 24,
              paddingBottom: 8,
            }}
          >
            <img src="https://d9hhrg4mnvzow.cloudfront.net/www.lamejorconstitucion.cl/672b77a8-lmc-logo-v3_104q02p04402500d00i028.png" alt='La Mejor Constitución' />
            {/* <Logo
              width={'90%'}
              style={{
                maxHeight: 330,
                paddingBottom: 32,
                paddingTop: 8
              }}
            /> */}
          </Grid.Column>
          <Grid.Column style={{}}>
            <Header as='h2' icon>
              ¡Hola {userInfo.firstName}!
              <Header.Subheader
                style={{
                  paddingLeft: 20,
                  paddingRight: 20,
                  marginTop: 16
                }}
              >
                ¡Te damos la bienvenida a <strong>"Prueba App Live"</strong>! Este es un
                espacio digital diseñado para interactuar con preguntas y
                respuestas durante nuestro encuentro en vivo. A lo largo de la conversación
                aparecerán preguntas que podrás responder con video, audio,
                texto o fotos, sé muy creativo en la forma de hacerlo y
                aprovechemos para ¡¡crear juntos!!
              </Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column
            className='ExplainMalabarismo'
            style={{
              backgroundColor: '#B10099',
              color: '#FFFFFF'
            }}
          >
            <Header as='h3' style={{
              textAlign: 'center',
              paddingTop: 24
            }}>
              <BallsRightUp width={48} className='BallRightUp' />
              Creemos que a los chilenos es mucho más lo que nos une que lo que nos separa, te invitamos a contestar estas declaraciones constitucionales*, para ver si estamos en lo correcto. 
            </Header>
            <BallLeftDown width={48} className='BallLeftDown' />
          </Grid.Column>
          <Grid.Column
            style={{
              marginTop: 16
            }}
            className='PreguntasYRespuestas'
          >
            <Header
              as='h2'
              style={{
                margin: '0px 12px',
                textAlign: 'center',
                marginBottom: 32
              }}
            >
              <Header.Subheader>
                A continuación te invitamos a participar durante la convención
                respondiendo las siguientes preguntas que te aparecerán a medida
                de que avancemos en la jornada.
              </Header.Subheader>
            </Header>
            {questions && questions.length > 0 ? (
              <Carousel key={questions[0].title} questions={questions}>
                {questions.map((question) => (
                  <FeedCard {...question} fetchMoreAnswers={fetchMoreAnswers} />
                ))}
              </Carousel>
            ) : (<>
              <Lottie
                options={{
                  animationData,
                  autoplay: true,
                  loop: true
                }}
                height={256}
                width={256}
              />
              <Header as='h4' className='EmptyHeader'>
                Presta atención al encuentro
                <Header.Subheader>
                  En los próximos minutos recibirás la primera pregunta para comenzar.
                </Header.Subheader>
              </Header>
            </>)}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}
