import React, { useEffect, useState } from 'react'
import Lottie from 'react-lottie'
import { Button, Header } from 'semantic-ui-react'
import 'react-phone-number-input/style.css'
import Input from 'react-phone-number-input/input'
import { isValidPhoneNumber } from 'react-phone-number-input'

import animationData from '../../Assets/Cellphone.json'

import './EasyLoginRequired.scss'
import { gql, useMutation } from '@apollo/client'

export const USER_SENDSMS = gql`
  mutation UserSendSMS($UserSendSMSInput: UserSendSMSInput!) {
    UserSendSMS(input: $UserSendSMSInput) {
      success
      state
      text
    }
  }
`

export default function EasyLoginRequired() {
  const [isSent, setIsSend] = useState<boolean>(
    !!localStorage.getItem('app@login/isSent')
  )

  const [value, setValue] = useState<any>()
  const [nombr, setNombr] = useState<string>()
  const [enabled, setEnabled] = useState<boolean>(false)

  const [sendSMS, { data, loading, error }] = useMutation(USER_SENDSMS)

  useEffect(() => {
    const isValid =
      (value ? isValidPhoneNumber(value) : false) &&
      !!(nombr && nombr?.trim().length > 0)
    setEnabled(isValid)
  }, [value, nombr])

  useEffect(() => {
    if (data) {
      if (data?.UserSendSMS?.state === 'GENERATE_SMS') {
        localStorage.setItem('app@login/isSent', 'true')
        setIsSend(true)
      } else {
        localStorage.removeItem('app@login/isSent')
        setIsSend(false)
        alert('Ocurrió un error al solicitar el registro, intenta nuevamente.')
      }
    }
  }, [data, error])

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      submit()
    }
  }

  const submit = () => {
    if (enabled) {
      sendSMS({
        variables: {
          UserSendSMSInput: {
            name: nombr,
            phone: value
          }
        }
      })
    }
  }

  return (
    <div className='EasyLoginRequired'>
      <Header as='h2' icon>
        <Lottie
          options={{
            animationData,
            autoplay: true,
            loop: true
          }}
          height={156}
          width={256}
          style={{
            marginBottom: 32
          }}
        />
        Inicio de Sesión obligatorio
        <Header.Subheader
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            marginTop: 16
          }}
        >
          Hola, para ingresar a <strong>"Prueba App Live"</strong> debes
          completar tu <strong>nombre y número de teléfono</strong> e iniciar
          sesión con el enlace que te enviarémos.
          <br />
          <br />
          {!isSent && (
            <div
              className='ui large input'
              style={{
                flexDirection: 'column'
              }}
            >
              <input
                placeholder='Nombre...'
                value={nombr}
                onChange={(e) => setNombr(e.target.value)}
              />
              <Input
                international={true}
                style={{
                  marginTop: 6
                }}
                placeholder='9 1234 6789'
                country='CL'
                defaultCountry='CL'
                autoFocus
                withCountryCallingCode={true}
                value={value}
                onChange={(val) => setValue(val)}
                onKeyDown={handleKeyDown}
              />
            </div>
          )}
          {isSent && (
            <div>
              <p
                style={{
                  color: '#B10099',
                  fontWeight: 'bold'
                }}
              >
                Revisa tus mensajes de texto (SMS) para iniciar sesión.
              </p>
            </div>
          )}
          {!isSent && (
            <>
              <br />
              <br />
              <Button
                loading={loading}
                onClick={() => submit()}
                size='large'
                disabled={!enabled}
                color='violet'
              >
                Continuar
              </Button>
              <br />
            </>
          )}
          <br /> Si tienes problemas comunícate con nosotros a{' '}
          <a href='mailto:contingente@contingente.cl'>
            <strong>contingente@contingente.cl</strong>
          </a>{' '}
          y te ayudaremos lo más pronto ponsible.
        </Header.Subheader>
        <small
          style={{
            fontSize: 14
          }}
        >
          <br />
          Una idea de &copy; Contingente 2022
        </small>
      </Header>
    </div>
  )
}
