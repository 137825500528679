import { useEffect, useState } from 'react'


import { useHistory } from 'react-router-dom'

import useURLQuery from '../../Hooks/useURLQuery'

import { USER_LOGIN } from '../connections'
import { useMutation } from '@apollo/client'

import { attemptLogin } from '../../Store/Actions'
import { useDispatch } from 'react-redux'

import './Login.scss'
import LoadingScreen from '../../Components/LoadingScreen/LoadingScreen'

export default function Login() {
    // Hooks
    const history = useHistory()
    const dispatch = useDispatch()
    const query = useURLQuery()

    // State
    const [_t, ] = useState<string|null>(query.get('t'))

    // Mutation
    const [userLogin, { data, error }] = useMutation(USER_LOGIN)

    // Effects
    useEffect(() => {
        if (!_t) {
            /** No token in URL */
            return history.push(`/`)
        } else {
            /** Token in URL */
            userLogin({
                variables: {
                    UserLoginInput: {
                        token: _t
                    }
                }
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_t])
    
    useEffect(() => {
        if (error) {
            return history.push('/', {
                error
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    useEffect(() => {
        if (data?.UserLogin?.success) {
            const { token, userdata, state } = data.UserLogin

            switch(state) {
                case 'OK_LOGIN':
                    const isLogged = dispatch<any>(
                        attemptLogin({
                            token,
                            userdata
                        })
                    )
                    if (isLogged) {
                        history.push('/event')
                    }
                    break
                default:
                    history.push('/', {
                        error: {
                            message: 'Error al iniciar sesión'
                        }
                    })
                    break
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, dispatch, history])

    return <LoadingScreen />
}