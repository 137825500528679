import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState
} from 'react'
import { useSwipeable } from 'react-swipeable'
import { Button, Grid, Label } from 'semantic-ui-react'
import { Wrapper, CarouselContainer, CarouselSlot, PREV, NEXT } from './styles'

type Direction = typeof PREV | typeof NEXT

interface CarouselState {
  pos: number
  sliding: boolean
  dir: Direction
}

type CarouselAction =
  | { type: Direction; numItems: number }
  | { type: 'stopSliding' }

interface Props extends PropsWithChildren<{}> {
  questions?: any[]
}

const getOrder = (index: number, pos: number, numItems: number) => {
  return index - pos < 0 ? numItems - Math.abs(index - pos) : index - pos
}

const getInitialState = (numItems: number): CarouselState => ({
  pos: numItems - 1,
  sliding: false,
  dir: NEXT
})

const Carousel: FunctionComponent<Props> = (props) => {
  const { questions } = props
  const numItems = React.Children.count(props.children)
  const [state, dispatch] = React.useReducer(reducer, getInitialState(numItems))
  console.log(numItems)

  const slide = (dir: Direction) => {
    dispatch({ type: dir, numItems })
    setTimeout(() => {
      dispatch({ type: 'stopSliding' })
    }, 50)
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => slide(NEXT),
    onSwipedRight: () => slide(PREV),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  })

  const [pending, setPending] = useState<number>(0)

  useEffect(() => {
    setPending(questions?.filter((q) => !q.answered).length || 0)
  }, [questions])

  return (
    <div {...handlers}>
      <Wrapper>
        {numItems > 1 && <Button.Group
          fluid
          style={{
            paddingRight: 48,
            marginLeft: 24,
            marginBottom: 32
          }}
        >
          <Button color='teal' onClick={() => slide(PREV)}>
            Anterior
          </Button>
          <Button.Or text={'o'} />
          <Button color='teal' onClick={() => slide(NEXT)}>
            Siguiente
          </Button>
        </Button.Group>}
        {pending && pending > 0 && (
          <Grid>
            <Grid.Row columns={1} className={`QuestionInfo ${numItems === 1 ? 'QuestionInfoPaddingTop' : ''}`}>
              <Grid.Column>
                Tienes &nbsp;
                <Label circular color={'red'} key={'pendingLabel'}>
                  {pending}
                </Label>
                &nbsp; pregunta{pending > 1 ? 's' : ''} por contestar.
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
        {numItems > 1 ? (
          <CarouselContainer dir={state.dir} sliding={state.sliding}>
            {React.Children.map(props.children, (child, index) => {
              return (
                <CarouselSlot order={getOrder(index, state.pos, numItems) + 1}>
                  {child}
                </CarouselSlot>
              )
            })}
          </CarouselContainer>
        ) : (
          React.Children.map(props.children, (child, index) => {
            return <div className='FixWidth'>{child}</div>
          })
        )}
      </Wrapper>
    </div>
  )
}

function reducer(state: CarouselState, action: CarouselAction): CarouselState {
  switch (action.type) {
    case PREV:
      return {
        ...state,
        dir: PREV,
        sliding: true,
        pos: state.pos === 0 ? action.numItems - 1 : state.pos - 1
      }
    case NEXT:
      return {
        ...state,
        dir: NEXT,
        sliding: true,
        pos: state.pos === action.numItems - 1 ? 0 : state.pos + 1
      }
    case 'stopSliding':
      return { ...state, sliding: false }
    default:
      return state
  }
}

export default Carousel
