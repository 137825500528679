import React from 'react'
import { Header, Icon } from 'semantic-ui-react'

import './PassengerAlert.scss'

type Props = {
    alertData: any
}

export default function PassengerAlert({ alertData }: Props) {
  return (
    <div className='PassengerWrapper'>
      <div className='PassengerAlert'>
        <Header as='h2' icon>
          <Icon name='bell outline' size='huge' />
          <Header.Subheader>
            Nueva pregunta
          </Header.Subheader>
          <Header.Content>
              {alertData.title}
          </Header.Content>
        </Header>
      </div>
    </div>
  )
}
