import { Button, Card, Feed } from 'semantic-ui-react'

import { es } from 'date-fns/locale'
import { formatDistance } from 'date-fns'

import { useState } from 'react'

import './FeedCard.scss'
import ModalSelector from '../ModalSelector/ModalSelector'
import useMobile from '../../Hooks/useMobile'
import FancyImage from '../FancyImage/FancyImage'
import { OperationVariables, QueryLazyOptions } from '@apollo/client'

type Props = {
  id: number
  title: string
  answered: boolean
  Answers?: any[]
  enabledViewMore: number
  fetchMoreAnswers: (
    options?: QueryLazyOptions<OperationVariables> | undefined
  ) => void
}

type TypesTypes = 'TEXT' | 'IMAGE' | 'VIDEO' | 'VOICE'

const doAuthorText = new Map([
  [1, 'escribió: '],
  [2, 'publicó una fotografía: '],
  [3, 'publicó un video: '],
  [4, 'envió una grabación: ']
])

export default function FeedCard({
  id,
  title,
  answered,
  Answers,
  enabledViewMore,
  fetchMoreAnswers
}: Props) {
  const modalState = useState<boolean>(false)
  const typeState = useState<TypesTypes>()
  const { isMobile } = useMobile()

  return (
    <>
      <ModalSelector
        modalState={modalState}
        typeState={typeState}
        questionId={id}
        title={title}
      />
      <Card
        style={{
          marginLeft: 24,
          width: isMobile ? '100%' : '100%',
          marginTop: 16
        }}
        key={id}
        className={answered ? 'Answered' : 'Pending'}
      >
        <Card.Content>
          <Card.Header>{title}</Card.Header>
        </Card.Content>
        {(Answers?.length || 0) > 0 && (
          <Card.Content>
            <Feed>
              {Answers?.map((answer: any) => {
                if (answer.type !== 1) {
                  answer.answer = answer.answer.indexOf('https') !== 0 ? `https://${answer.answer}` : answer.answer
                }
                return (
                  <Feed.Event key={`${id}${answer.id}`}>
                    <Feed.Content>
                      <Feed.Date>
                        {formatDistance(
                          new Date(answer.createdAt),
                          new Date(),
                          {
                            addSuffix: true,
                            locale: es
                          }
                        )}
                      </Feed.Date>
                      <Feed.Summary>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a href='#'>
                          {answer.Author.firstName} {answer.Author.lastName}
                        </a>{' '}
                        {doAuthorText.get(answer.type)}
                      </Feed.Summary>
                      <Feed.Extra
                        text={answer.type === 1}
                        images={answer.type === 2}
                      >
                        {answer.type === 1 && answer.answer}
                        {answer.type === 2 && (
                          <span>
                            <FancyImage
                              src={answer.answer}
                              alt={`Respuesta de Comentario de ${answer.Author.firstName}}`}
                            />
                          </span>
                        )}
                        {answer.type === 3 && (
                          <span>
                            <video
                              controls
                              playsInline
                              preload='metadata'
                              controlsList='nodownload'
                              style={{ maxHeight: 250, maxWidth: '100%' }}
                            >
                              Tu navegador no soporta videos
                              <source
                                src={`${answer.answer}#t=0.001`}
                                type='video/mp4'
                              />
                            </video>
                          </span>
                        )}
                        {answer.type === 4 && (
                          <audio controls controlsList='nodownload'>
                            <source src={answer.answer} type='audio/wav' />
                            Tu navegador no soporta audio
                          </audio>
                        )}
                      </Feed.Extra>
                    </Feed.Content>
                  </Feed.Event>
                )
              })}
            </Feed>
          </Card.Content>
        )}
        <Card.Content extra>
          <Button
            type='submit'
            color={answered ? 'green' : 'red'}
            fluid
            size='large'
            content='Responder'
            onClick={() => modalState[1](true)}
          />
          {enabledViewMore > 0 && (
            <Button
              type='button'
              color={'teal'}
              fluid
              size='medium'
              content={`Ver ${enabledViewMore} respuesta${
                enabledViewMore > 1 ? 's' : ''
              } más...`}
              style={{
                marginTop: 6
              }}
              onClick={() => {
                fetchMoreAnswers({
                  variables: {
                    UserMoreAnswersInput: {
                      questionId: id,
                      lessThanId: Answers?.[Answers.length - 1].id
                    }
                  }
                })
              }}
            />
          )}
        </Card.Content>
      </Card>
    </>
  )
}
