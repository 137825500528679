import { useContext, useEffect, useState } from 'react'

import {
  Button,
  Form,
  Header,
  Icon,
  Modal,
  TextArea
} from 'semantic-ui-react'

import { useMutation } from '@apollo/client'
import { useSelector } from 'react-redux'
import AudioReactRecorder, { RecordState } from 'audio-react-recorder'

import { QUESTION_ANSWER } from '../../Views/connections'
import LoaderContext from '../../Library/Context/LoaderContext'

import { ButtonBoxes } from './styles'

const IconsTypes = new Map([
  ['TEXT', 'font'],
  ['IMAGE', 'image'],
  ['VIDEO', 'video'],
  ['VOICE', 'volume up']
])

const DescriptionTypes = new Map([
  ['TEXT', 'Texto'],
  ['IMAGE', 'Imagen'],
  ['VIDEO', 'Video'],
  ['VOICE', 'Voz']
])

type TypesTypes = 'TEXT' | 'IMAGE' | 'VIDEO' | 'VOICE' | undefined

type Props = {
  modalState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
  typeState: [TypesTypes, React.Dispatch<React.SetStateAction<TypesTypes>>]
  questionId: number
  title: string
}

export default function ModalSelector({
  modalState,
  typeState,
  questionId,
  title
}: Props) {
  const { token } = useSelector((state: any) => state)

  const [modal, setModal] = modalState
  const [type, setType] = typeState
  const [step, setStep] = useState<number>(1)

  const { setLoading } = useContext(LoaderContext)
  const [answer, setAnswer] = useState<any>()
 
  const [havePermissions, setHavePermissions] = useState<any>(false)

  const [doAnswer, e_AnswersEvents] = useMutation(QUESTION_ANSWER, {
    context: {
      headers: {
        authorization: `Bearer ${token}`
      }
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  })

  useEffect(() => {
    setLoading(e_AnswersEvents.loading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [e_AnswersEvents.loading])

  useEffect(() => {
    if (e_AnswersEvents.data?.QuestionAnswer?.success) {
      setType(undefined)
      setAnswer(undefined)
      setModal(false)
      setStep(1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [e_AnswersEvents.data])

  useEffect(() => {
    if (!modal) {
      setType(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal])

  const getTypeId = () => {
    switch (type) {
      case 'TEXT':
        return 1
      case 'IMAGE':
        return 2
      case 'VIDEO':
        return 3
      case 'VOICE':
        return 4
      default:
        return 1
    }
  }

  const back = () => {
    switch (step) {
      case 1:
        setModal(false)
        break
      default:
        setStep(step - 1)
        break
    }
  }

  const checkPermissions = () => {
    const permissions = navigator.mediaDevices.getUserMedia({audio: true, video: false})
    permissions.then((stream) => {
        setHavePermissions(true);
        next(true)
    })
    .catch((err) => {
      setHavePermissions(false)
      alert('Si quieres grabar tu voz, debes dar permiso al micrófono para ser grabado por la aplicación.')
    });
  }

  const next = (_force = false) => {
    switch (step) {
      case 1:
          if (type === 'VOICE' && !havePermissions && !_force) {
              checkPermissions()
          } else {
              setStep(step + 1)
          }
        break
      case 2:
        const QuestionAnswerInput = {
          questionId,
          typeId: getTypeId()
        }
        if (type === 'TEXT') {
          // @ts-ignore
          QuestionAnswerInput.answer = answer
        } else {
          // @ts-ignore
          QuestionAnswerInput.file = answer
        }
        doAnswer({
          variables: {
            QuestionAnswerInput
          }
        })
        break
    }
  }

  return (
    <Modal
      basic
      onClose={() => setModal(false)}
      open={modal}
      size='small'
      closeOnDimmerClick={false}
      className='ModalActionsAnswer'
    >
      <Header icon>
        {/* @ts-ignore */}
        <Icon name={step === 1 ? 'add' : IconsTypes.get(type)} />
        <small>"{title}"</small>
        <br />
        <span
          style={{
            fontWeight: 'lighter'
          }}
        >
          {step === 1
            ? 'Responder una pregunta'
            : // @ts-ignore
              `Respondiendo con ${DescriptionTypes.get(type)}`}
        </span>
      </Header>
      <Modal.Content>
        <p>
          Selecciona un tipo de material para responder a esta pregunta. Si
          deseas cambiar, puedes volver con el botón "Volver" y seleccionar otro
          tipo de material.
        </p>
        {step === 1 && (
          <ButtonBoxes>
            {['TEXT', 'IMAGE', 'VIDEO', 'VOICE'].map<any>((_type) => (
              <Button
                key={_type}
                onClick={() => {
                  // @ts-ignore
                  setType(_type)
                }}
                style={{
                  margin: '0.5em',
                  width: '40%',
                  opacity: type === _type ? 1 : 0.75
                }}
                basic={type !== _type}
                // @ts-ignore
                color={_type === type ? 'red' : 'inverted'}
                // color={'red'}
              >
                {IconsTypes.has(_type) && (
                  // @ts-ignore
                  <Icon name={IconsTypes.get(_type)} size='big' color='white' />
                )}
                <span>{DescriptionTypes.get(_type)}</span>
              </Button>
            ))}
          </ButtonBoxes>
        )}
        {step === 2 && (
          <ToAnswer
            key={type}
            type={type}
            answer={answer}
            setAnswer={setAnswer}
            doAnswer={doAnswer}
            getTypeId={getTypeId}
            questionId={questionId}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button size='large' color='red' inverted onClick={back}>
          <Icon name='angle left' /> Volver
        </Button>
        {(step === 1 || (step === 2 && [1, 4].includes(getTypeId()))) && (
          <Button
            size='large'
            color='green'
            disabled={(step === 1 ? (type === undefined) : (answer === undefined))}
            active={!(step === 1 ? (type === undefined) : (answer === undefined))}
            inverted
            onClick={() => next()}
          >
            Siguiente <Icon name='arrow right' />
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  )
}

const ToAnswer = ({
  type,
  answer,
  setAnswer,
  doAnswer,
  getTypeId,
  questionId
}: {
  type: TypesTypes
  answer: any
  setAnswer: any
  doAnswer: any
  getTypeId: any
  questionId: number
}) => {

  const [recordState, setRecordState] = useState<any>()
  
    const onStop = (audioData: any) => {
      //audioData contains blob and blobUrl
      audioData.blob.name = 'audio.wav'
      setAnswer(audioData.blob)
      console.log('audioData', audioData)
    }
  
  
    const start = () => {
      setRecordState(RecordState.START)
      setAnswer(undefined)
    }
   
    const stop = () => {
      setRecordState(RecordState.STOP)
    }

  const uploadFile = (validity: any, file: any) => {
    if (validity.valid) {
      doAnswer({
        variables: {
          QuestionAnswerInput: {
            questionId,
            typeId: getTypeId(),
            file
          }
        }
      })
    } else {
        alert('El archivo seleccionado no es permitido.')
    }
  }

  switch (type) {
    case 'TEXT':
      return (
        <Form
          onSubmit={(e, datad) => {
            //   modalState[1](true)
          }}
        >
          <TextArea
            fluid
            // type='textarea'
            rows={2}
            placeholder='Escribe una respuesta...'
            // value={answer}
            onChange={(e) => {
              setAnswer(e.target.value)
            }}
          />
        </Form>
      )
    case 'IMAGE':
      return (
        <input
          //   placeholder='Escribe la URL de la imagen...'
          type='file'
          accept='image/*'
          onChange={({
            target: {
            validity,
            // @ts-ignore
            files: [file]
            }
        }) => uploadFile(validity, file)}
        />
      )
    case 'VIDEO':
      return (
        <input
          //   placeholder='Escribe la URL de la imagen...'
          type='file'
          accept='video/*'
          onChange={({
            target: {
            validity,
            // @ts-ignore
            files: [file]
            }
        }) => uploadFile(validity, file)}
        />
      )
    case 'VOICE':
      return (
        <div>
             <AudioReactRecorder state={recordState} onStop={onStop} backgroundColor={'#00000050'} foregroundColor={'#FFFFFF'} />
 
            {(!recordState || [RecordState.NONE, RecordState.STOP].includes(recordState)) && <Button negative fluid onClick={start}>{recordState === RecordState.None ? 'Iniciar' : 'Repetir'} grabación</Button>}
            {recordState === RecordState.START && <Button negative fluid onClick={stop}>Detener grabación</Button>}
        </div>
      )
    default:
      return <></>
  }
}
