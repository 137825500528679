import React from 'react';

import {
  BrowserRouter as Router,
  Switch
} from "react-router-dom";

import { ApolloClient, ApolloProvider, InMemoryCache, split } from '@apollo/client'
import fetch from 'cross-fetch';

import { LoaderProvider } from './Library/Context/LoaderContext';

import { Provider } from 'react-redux'
import configureStore from './Store'
import { PersistGate } from 'redux-persist/integration/react'

// import { split } from 'apollo-link';
// import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { createUploadLink } from "apollo-upload-client";

import './App.scss'
import 'semantic-ui-css/semantic.min.css'
import useMobile from './Hooks/useMobile';
import { PrivateRoute, PublicRoute } from './Routes'; // AdminRoute, 

// /** Generic views. */
// import Verify from './Views/Verify';

// /** Student views. */
// import Courses from './Views/Courses';
// import CourseView from './Views/CourseView';

// /** Administrator views. */
// import UserList from './Views/Admin/UserList';
// import UserCreate from './Views/Admin/UserCreate';
// import Report from './Views/Admin/Report';
// import PillCreate from './Views/Admin/PillCreate';

// import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import PillList from './Views/Admin/PillList';
// import CategoryList from './Views/Admin/CategoryList';
// import CategoryCreate from './Views/Admin/CategoryCreate';
import EasyLoginRequired from './Views/EasyLoginRequired/EasyLoginRequired';
import LoadingScreen from './Components/LoadingScreen/LoadingScreen';
import Login from './Views/Login/Login';
import Event from './Views/Event/Event';

const { persistor, store } = configureStore()

const uri = process.env.NODE_ENV === 'production' ? 'api-live-events.contingente.cl' : '192.168.1.6:4000'
const formatUri = (protocol: string) => {
  const isProd = process.env.NODE_ENV === 'production'
  return isProd ? `${protocol}s://${uri}` : `${protocol}://${uri}`
}

// const httpLink = new HttpLink({
//   uri: `${formatUri('http')}/graphql`,
//   fetch
// })

// Create a WebSocket link:
const wsLink = new WebSocketLink({
  uri: formatUri('ws'),
  options: {
    reconnect: true
  }
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  // @ts-ignore
  wsLink,
  createUploadLink({
    uri: `${formatUri('http')}/graphql`,
    fetch
  }),
);


const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    addTypename: false
  }),
  defaultOptions: {
    mutate: {
      errorPolicy: 'all'
    }
  },
});

export default function App() {
  const [loading, setLoading] = React.useState<boolean>(false)
  const { ViewportClass } = useMobile()

  return <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ApolloProvider client={client}>
            <LoaderProvider value={{ loading, setLoading }}>
              {loading && <LoadingScreen />}              
              <Router>
                <div className={`App ${ViewportClass}`}>
                  <Switch>
                    <PublicRoute restricted={true} component={EasyLoginRequired} path="/" exact />
                    <PublicRoute restricted={true} component={Login} path="/login" exact />
                    <PrivateRoute restricted={true} component={Event} path="/event" exact />
                    {/* <AdminRoute component={Courses} path="/courses" exact />
                    <AdminRoute component={CourseView} path="/course/view/:learningRouteId/:courseId" />
                    <AdminRoute component={UserList} path="/admin/users/list" />
                    <AdminRoute component={UserCreate} path="/admin/users/create" exact />
                    <AdminRoute component={UserCreate} path="/admin/users/edit/:userId" exact />
                    <AdminRoute component={PillCreate} path="/admin/courses/create" exact />
                    <AdminRoute component={PillCreate} path="/admin/courses/edit/:learningRouteId/:resourceId" exact />
                    <AdminRoute component={PillList} path="/admin/courses/list" exact />
                    <AdminRoute component={CategoryList} path="/admin/courses/list-category" exact />
                    <AdminRoute component={CategoryCreate} path="/admin/courses/create-category" exact />
                    <AdminRoute component={CategoryCreate} path="/admin/courses/edit-category/:categoryId" exact />
                    <AdminRoute component={Report} path="/admin/report" exact />
                    <PublicRoute restricted={true} component={Verify} path="/verify/:token" /> */}
                  </Switch>
                </div>
            </Router>
          </LoaderProvider>
        </ApolloProvider>
      </PersistGate>
    </Provider>
}
