import { Route, Redirect } from 'react-router-dom'

import { connect } from 'react-redux'
import useMobile from '../Hooks/useMobile'

const mapStateToProps = ({ isLogged }: any) => {
    return { isLogged }
}

const PublicRoute = ({component: Component, restricted, isLogged, ...rest}: any) => {
    const isMobile = useMobile()

    return (
        <Route {...rest} render={(props: any) => {
            const stateProps = {
                isLogged,
                isMobile
            }
            return (isLogged && restricted ?
                <Redirect to="/event" />
            : <Component {...props} {...stateProps}  />
        )}} />
    )
}

export default connect(mapStateToProps)(PublicRoute)