import { useState } from 'react'
import { Modal, Image } from 'semantic-ui-react'
import './FancyImage.scss'

type FancyImageProps = {
  src: string
  alt: string
}

export default function FancyImage({ src, alt }: FancyImageProps) {
  const [modal, setModal] = useState<boolean>(false)



  return (
    <div className='FancyImage'>
        <Modal size='small' open={modal} onClose={() => setModal(false)} className='FancyModal'>
            <Image src={src} alt={alt} size='big' />
        </Modal>
      <img src={src} alt={alt} onClick={() => setModal(true)} />
    </div>
  )
}
